import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter,Routes,Route } from "react-router-dom";
import CreateSubscriber from './components/CreateSubscriber';
import Success from './components/Success';
import InfoPage from './components/InfoPage';
import ActiveCampaignForm from './components/ActiveCampaignForm';
import lanks_vinyl from './media/lanks_vinyl.jpg';
import lanks_vinyl_mockup from './media/lanks_vinyl_mockup.png';
import oceanAlleyPress from './media/OA_press.jpg';
import oceanAlleyCover from './media/oa_new_pic.png';
import Spirits from './media/LANKS_Spirits.jpg';
import LanksTC from './components/LanksTC';
import viviRincon from './media/Vivi_Rincon011_edit.jpg';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App title="Zantipi forms" />}>
        <Route index element={<InfoPage />} />
        
        {/* Klaviyo pages */}
        <Route path="lanks/" element={<CreateSubscriber artist="LANKS" termsLink="/lanks/tc" slug="lanks" listId="VGMFji" gtmTag="GTM-M7XCTGL" image={Spirits} headline="SPIRITS PT. 1 + 2" copy="I'm giving away 5x signed vinyl of the record that has changed my life - ft Rearview, Cold Blood, Spiritual Man, Stronger Than etc etc. sign up to enter friends x" />}/>
        <Route path="lanks/success/" element={<Success artist="LANKS" />}/>
        <Route path="lanks/tc" element={<LanksTC />}/>

        <Route path="ocean-alley/" element={<CreateSubscriber artist="Ocean Alley" slug="ocean-alley" listId="SpVRrG" gtmTag="GTM-WK5LB46" image={oceanAlleyCover} copy="Sign up to the OA mailing list" />}/>
        <Route path="ocean-alley/success/" element={<Success artist="Ocean Alley" />}/>

        <Route path="vivi-rincon/" element={<CreateSubscriber artist="vivi rincon" slug="vivi-rincon" listId="RUBHuA" gtmTag="GTM-TV2Q2B8" image={viviRincon} headline="vivi rincon" copy="Sign up to my mailing list for first access to new music and tickets! x" />}/>
        <Route path="vivi-rincon/success/" element={<Success artist="vivi rincon" />}/>

        {/* active campaign pages */}
        <Route path="boyandbear/" element={<ActiveCampaignForm acDomain="boyandbear" slug="boyandbear" artist="Boy & Bear" />}/>
        <Route path="boyandbear/success/" element={<Success artist="Boy & Bear" />}/>

      </Route>
    </Routes>
  </BrowserRouter>
);