import React from 'react'

const LanksTC = () => {
  return (
    <div className="px-4 sm:px-6 lg:px-8 w-1/2">
        <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto text-center">
                <h1 className='text-xl font-bold'>LANKS ‘SPIRITS’ Double LP Vinyl Competition</h1>
                <h3>Schedule to Terms of Entry </h3>
            </div>
        </div>
        <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <table className="min-w-full divide-y divide-gray-300 table-auto">
                    <tbody className="divide-y divide-gray-200">
                        
                        <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6 md:pl-0">
                                Name of Promotion: 
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                LANKS 'SPIRITS'  Vinyl Competition
                            </td>
                        </tr>

                        <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6 md:pl-0">
                                Entry Restrictions:   
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                Entrants will require an email to complete some tasks.  
                            </td>
                        </tr>

                        <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6 md:pl-0">
                                Promotion Period:   
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                Starts 12:00pm (AEST) Sept 28th 2022 | Ends 12:00pm (AEST) Oct 31st 2022   
                            </td>
                        </tr>

                        <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6 md:pl-0">
                                Entry Method:   
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                <p>1. Entrants must become an email subscriber to LANKS’ mailing list to enter the running to win a copy of LANKS’ ‘SPIRITS’ Double LP.</p> 
                            </td>
                        </tr>

                        <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6 md:pl-0">
                                Entry Limits:    
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                All entries must be received during the Promotion Period. Entrants may only enter the Promotion once.    
                            </td>
                        </tr>

                        <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6 md:pl-0">
                                Draw Details:    
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                                5 x draw from all valid entries held by LANKS at the end of the promotion period will take place: | Time: 1:00pm (AEST), Date: November 7st, 2022    
                            </td>
                        </tr>

                        <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6 md:pl-0">
                            Prize Details:    
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            1 x SPIRITS Double LP per winner | 5 x winners   
                            </td>
                        </tr>

                        <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6 md:pl-0">
                            Prize Value:      
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            SPIRITS Double LP - AUD $50 + GST  
                            </td>
                        </tr>

                        <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6 md:pl-0">
                            Winner Notification:       
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            Winners will be notified by email on Wednesday, November 9th, 2022, 12.00 PM AEST.
                            </td>
                        </tr>

                        <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6 md:pl-0">
                            Prize Claim Date and Time:        
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            Prizes must be claimed by 12:00pm (AEST) on Tuesday, November 16th, 2022.
                            </td>
                        </tr>

                        <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6 md:pl-0">
                            Unclaimed Prize Draw Date:          
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            If the winner does not claim their prize by 12:00pm (AEST) on Tuesday, November 16th, 2022, a new winner will be redrawn and notified at 12pm (AEST) on Wednesday, November 17th, 2022 and must respond by 12pm (AEST) on Friday, November 19th, 2022
                            </td>
                        </tr>

                        <tr>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-bold text-gray-900 sm:pl-6 md:pl-0">
                            Prize Delivery:         
                            </td>
                            <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-500">
                            The recipient of the prize will have to provide their preferred delivery address. Prize will be shipped to the winner. LANKS and team are not liable for any damages to the product. Shipping cost will be covered by LANKS and team.
                            </td>
                        </tr>

                        
                    </tbody>
                </table>
            </div>
            </div>
        </div>
    </div>
  )
}

export default LanksTC