import React from 'react'

export default function Success(props) {
  return (
    <>
      <div className="h-screen bg-gradient-to-r from-[#662e91] to-[#e34a63] mx-auto px-4 sm:px-6 md:px-8 py-8">
      {/* <div className="h-full bg-[#fff0b3] mx-auto px-4 sm:px-6 md:px-8 py-8"> */}

          <div className="bg-white mx-auto max-w-lg px-4 sm:px-6 md:px-8 rounded-lg border-gray-300 shadow-lg py-8">

              {/* <div className="sm:flex-auto mx-auto text-center">

                  <img src={props.image}/>

              </div> */}

              
              <div className="sm:flex-auto text-center">

                  <h3 className="text-2xl font-medium leading-6 text-gray-900">{props.artist}</h3>
                  <p className="pt-2 mt-1 max-w-2xl text-sm text-gray-500">Thanks for subscribing — now go check your email :)</p>

              </div>

              

          </div>

          <div className="flex items-center justify-center pt-4">

              <img className="object-contain h-20 w-30 w-auto" src="https://drive.google.com/uc?export=view&id=1vlhQemRa3BVZlT6Q7po46ArjhfzqaWnR"/>

          </div>

      </div>

    </>
  )
}