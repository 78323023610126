import React, { Fragment, useState, useRef, useEffect } from "react";
import {Link, useNavigate} from 'react-router-dom';
import { CheckCircleIcon, XMarkIcon, CheckIcon, ChevronUpDownIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { Listbox, Transition } from '@headlessui/react'
import { Country, State, City }  from 'country-state-city';
import TagManager from 'react-gtm-module'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

let emailRegex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");


export default function CreateSubscriber(props) {

    const tagManagerArgs = {
        gtmId: props.gtmTag
      }
      TagManager.initialize(tagManagerArgs)

    useEffect(() => {
        window.dataLayer.push({
        event: 'pageview'
        });
    }, []);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // object attributes
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState(null);
    const [city, setCity] = useState(null);
    const [stateRegion, setStateRegion] = useState(null);
    const [country, setCountry] = useState({});
    const [postCode, setPostCode] = useState(null);
    const [mobileNumber, setMobileNumber] = useState(null);
    const [verified, setVerified] = useState(false);
    const [success, setSuccess] = useState(false);
    const [states, setStates] = useState([]);

    document.title = props.artist;

    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = props.artist;
        // document.title = props.artist;
      }, [props.artist]);

    const handleSubmit = (event) => {
        if (!verified) {
            return setError("All fields with an asterisk (*) must be completed correctly!");
        }
        setError(null);
        setLoading(true);
        submit(event, email, firstName, city, stateRegion, country, postCode, mobileNumber);
    }

    useEffect(() => {
        if (
            (email === null || email.length === 0) ||
            (emailRegex.test(email) === false) ||
            (firstName === null || firstName.length === 0) ||
            (city === null || city.length === 0) ||
            (stateRegion === null || stateRegion.length === 0) ||
            (country === null || country.length === 0) ||
            (postCode === null || postCode.length === 0)
            // (mobileNumber === null || mobileNumber.length === 0)
            ) {
                setVerified(false);
        } else {
            setVerified(true);
        }
    }, [email, firstName, city, stateRegion, country, postCode, mobileNumber]);

    useEffect(() => {
        setStates(State.getStatesOfCountry(country.isoCode).map((state) => {
            return state.name;
        }));
    }, [country]);

    const submit = async (event, email, firstName, city, stateRegion, country, postCode, mobileNumber) => {
        event.preventDefault();

        const data = {
          g: props.listId,
          email: email ?? '',
          $fields: '$first_name,$last_name,$source,$country,$region,$zip,$phone_number',
          $first_name: firstName ?? '',
          $source: 'CMA form',
          $country: country.name ?? '',
          $city: city ?? '',
          $region: stateRegion ?? '',
          $zip: postCode ?? '',
          $phone_number: mobileNumber ?? '',
        }

        const urlData = new URLSearchParams(data)

        const response = await fetch(`https://manage.kmail-lists.com/ajax/subscriptions/subscribe`, {
            method: 'POST',
            headers: {
                "content-type": "application/x-www-form-urlencoded",
                "cache-control": "no-cache"
              },
            body: urlData,
        })
        setLoading(false);
        try {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const json = await response.json();
            if (json.errors.length > 0) {
                throw Error(json.errors[0]);
            }
        } catch {
            console.log('hey')
            setError(error);
            return
        }

        window.dataLayer.push({
            event: 'subscribe',
            eventProps: {
                category: "subscriber",
                action: "signup",
                label: "subscriber",
                value: 0,
            }
          });
        
        setSuccess(true);
        console.log(`/${props.slug}/success/`)
        navigate(`/${props.slug}/success/`);
      }
    

    return (
        <>
            <div className="h-full bg-gradient-to-r from-[#662e91] to-[#e34a63] mx-auto px-4 sm:px-6 md:px-8 py-8">

                <div className="bg-white mx-auto max-w-lg px-4 sm:px-6 md:px-8 rounded-lg border-gray-300 shadow-lg py-8">

                    <div className="sm:flex-auto mx-auto text-center">

                        <img className="mx-auto rounded-lg shadow-sm" src={props.image}/>

                    </div>

                    
                    <div className="sm:flex-auto text-center mt-4">

                        <h3 className="text-2xl font-medium leading-6 text-gray-900">{props.headline}</h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">{props.copy}</p>

                    </div>

                    <form>

                        <div className="pt-8">
                            <div className="sm:col-span-4">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email*</label>
                                <div className="mt-1">
                                    <input name="email" type="email" value={email || ""} pattern="[a-z0-9]+@[a-z]+\.[a-z]{2,3}" onChange={(event) => {
                                        setEmail(event.target.value);
                                    }} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" required />
                                </div>
                            </div>
                        </div>

                        <div className="pt-8">
                            <div className="sm:col-span-4">
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name*</label>
                                <div className="mt-1">
                                    <input name="firstName" type="text" value={firstName || ""} onChange={(event) => {
                                        setFirstName(event.target.value);
                                    }} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                </div>
                            </div>
                        </div>

                        {/* Country select */}
                        <div className="pt-8">
                            <div className="sm:col-span-4">
                            <Listbox value={country.name} onChange={(event) => {
                                        setCountry(event);
                                    }}>
                                {({ open }) => (
                                    <>

                                    <Listbox.Label className="block text-sm font-medium text-gray-700">Country*</Listbox.Label>
                                    
                                    <div className="relative mt-1">

                                        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                        <span className="block truncate">{country.name || '---'}</span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                        </Listbox.Button>

                                        <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">

                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">

                                                {Country.getAllCountries().map((country) => (
                                                    
                                                    <Listbox.Option
                                                        key={country.isoCode}
                                                        className={({ active }) =>
                                                        classNames(
                                                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                            'relative cursor-default select-none py-2 pl-8 pr-4'
                                                        )
                                                        }
                                                        value={country}
                                                    >

                                                        {({ selected, active }) => (
                                                        <>
                                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                            {country.name}
                                                            </span>

                                                            {selected ? (
                                                            <span
                                                                className={classNames(
                                                                active ? 'text-white' : 'text-indigo-600',
                                                                'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                                                )}
                                                            >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                            ) : null}
                                                        </>
                                                        )}

                                                    </Listbox.Option>

                                                ))}
                                            </Listbox.Options>

                                        </Transition>

                                    </div>
                                    </>
                                )}
                                </Listbox>
                        </div>
                        </div>

                        {/* Region select */}
                        <div className="pt-8">
                            <div className="sm:col-span-4">
                            <Listbox value={stateRegion} onChange={(event) => {
                                        console.log('changed value: ')
                                        console.log(event)
                                        setStateRegion(event);
                                    }}>
                                {({ open }) => (
                                    <>

                                    <Listbox.Label className="block text-sm font-medium text-gray-700">Region*</Listbox.Label>
                                    
                                    <div className="relative mt-1">

                                        <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                        <span className="block truncate">{stateRegion || '---'}</span>
                                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                        </span>
                                        </Listbox.Button>

                                        <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">

                                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                
                                                {states.map((state) => (
                                                    
                                                    <Listbox.Option
                                                        key={state}
                                                        className={({ active }) =>
                                                        classNames(
                                                            active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                            'relative cursor-default select-none py-2 pl-8 pr-4'
                                                        )
                                                        }
                                                        value={state}
                                                    >

                                                        {({ selected, active }) => (
                                                        <>
                                                            <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                            {state}
                                                            </span>

                                                            {selected ? (
                                                            <span
                                                                className={classNames(
                                                                active ? 'text-white' : 'text-indigo-600',
                                                                'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                                                )}
                                                            >
                                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                            </span>
                                                            ) : null}
                                                        </>
                                                        )}

                                                    </Listbox.Option>

                                                ))}
                                            </Listbox.Options>

                                        </Transition>

                                    </div>
                                    </>
                                )}
                                </Listbox>
                            </div>
                        </div>

                        <div className="pt-8">
                            <div className="sm:col-span-4">
                                <label htmlFor="city" className="block text-sm font-medium text-gray-700">City*</label>
                                <div className="mt-1">
                                    <input name="city" type="text" value={city || ""} onChange={(event) => {
                                        setCity(event.target.value);
                                    }} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                </div>
                            </div>
                        </div>

                        <div className="pt-8">
                            <div className="sm:col-span-4">
                                <label htmlFor="postCode" className="block text-sm font-medium text-gray-700">ZIP / Post Code*</label>
                                <div className="mt-1">
                                    <input name="postCode" type="text" value={postCode || ""} onChange={(event) => {
                                        setPostCode(event.target.value);
                                    }} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                </div>
                            </div>
                        </div>

                        <div className="pt-8">
                            <div className="sm:col-span-4">
                                <label htmlFor="mobileNumber" className="block text-sm font-medium text-gray-700">Mobile Number</label>
                                <div className="mt-1">
                                    <input name="mobileNumber" type="text" value={mobileNumber || ""} onChange={(event) => {
                                        setMobileNumber(event.target.value);
                                    }} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                                </div>
                            </div>
                        </div>


                        {/* SUBMIT BUTTON */}
                        <button
                            type="button"
                            className="w-full py-2 my-8 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            // disabled={!verified}
                            onClick={handleSubmit}
                        >
                            Sign me up!
                        </button>

                        {props.termsLink && 
                            <div className="text-center">
                                <Link to={props.termsLink} className="text-gray-400" target="_blank" rel="noopener noreferrer" >
                                terms and conditions
                                </Link>
                            </div>
                        }
                        



                        {
                            success && 
                        <div className="rounded-md bg-green-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                <p className="text-sm font-medium text-green-800">Successfully uploaded</p>
                                </div>
                                <div className="ml-auto pl-3">
                                <div className="-mx-1.5 -my-1.5">
                                    <button
                                    type="button"
                                    className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                    >
                                    <span className="sr-only">Dismiss</span>
                                    <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                                </div>
                            </div>
                        </div>
                        }

                        {
                            error && 
                            
                            <div className="rounded-md bg-red-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                    </div>
                                    <div className="ml-3">
                                    <h3 className="text-sm font-medium text-red-800">{error}</h3>
                                    <div className="mt-2 text-sm text-red-700">
                                        <ul role="list" className="list-disc space-y-1 pl-5">
                                        <li>Check the email you gave is correct</li>
                                        <li>Check you've inputted all the required fields</li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* {
                            loading && <div>Loading!</div>
                        } */}

                    </form>

                </div>

                <div className="flex items-center justify-center pt-4">

                    <img className="object-contain h-20 w-30 w-auto" src="https://drive.google.com/uc?export=view&id=1vlhQemRa3BVZlT6Q7po46ArjhfzqaWnR"/>

                </div>

            </div>

        </>
      );
}







