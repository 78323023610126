import { Outlet } from 'react-router-dom';
import { useEffect, useState } from "react";

export default function App(props) {

  return (
    <>
      <Outlet />
    </>
    
  )
}
