import React, { Fragment, useState, useRef, useEffect } from "react";
import {useNavigate} from 'react-router-dom';
import { CheckCircleIcon, XMarkIcon, CheckIcon, ChevronUpDownIcon, XCircleIcon } from '@heroicons/react/20/solid'
import { Listbox, Transition } from '@headlessui/react'
import { Country, State, City }  from 'country-state-city';
import TagManager from 'react-gtm-module'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

let emailRegex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");

export default function ActiveCampaignForm(props) {

    const tagManagerArgs = {
        gtmId: 'GTM-TQ3XCNS'
      }
      TagManager.initialize(tagManagerArgs)

    useEffect(() => {
        window.dataLayer.push({
            event: 'pageview'
          });
    }, []);
    
    // window.dataLayer.push({
    //     event: 'pageview'
    //   });

    // console.log(window.dataLayer)

    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = props.artist;
        // document.title = props.artist;
      }, [props.artist]);

    // document.title = "Hey bud!";
    // document.title = props.artist;
    // console.log('location stuff: ')
    // console.log(document.location.origin)
    // console.log(document.location.pathname)
    // console.log(document.location.search)
    // console.log(document.title)
    // console.log('end location stuff.')

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // object attributes
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState(null);
    const [city, setCity] = useState(null);
    const [stateRegion, setStateRegion] = useState(null);
    const [country, setCountry] = useState({});
    const [postCode, setPostCode] = useState(null);
    const [mobileNumber, setMobileNumber] = useState(null);
    const [verified, setVerified] = useState(false);
    const [success, setSuccess] = useState(false);
    const [states, setStates] = useState([]);

    useEffect(() => {
        if (
            (email === null || email.length === 0) ||
            (emailRegex.test(email) === false) ||
            (firstName === null || firstName.length === 0) ||
            (city === null || city.length === 0) ||
            // (stateRegion === null || stateRegion.length === 0) ||
            (country === null || country.length === 0) ||
            (postCode === null || postCode.length === 0)
            // (mobileNumber === null || mobileNumber.length === 0)
            ) {
                setVerified(false);
        } else {
            setVerified(true);
        }
    }, [email, firstName, city, stateRegion, country, postCode, mobileNumber]);

    useEffect(() => {
        setStates(State.getStatesOfCountry(country.isoCode).map((state) => {
            return state.name;
        }));
    }, [country]);
  
    
    const handleSubmit = event => {
        console.log('handleSubmit! ')
        event.preventDefault()

        if (!verified) {
            return setError("All fields with an asterisk (*) must be completed correctly!");
        }
        setError(null);
        setLoading(true);

        window.dataLayer.push({
            event: 'subscribe',
            eventProps: {
                category: "subscriber",
                action: "signup",
                label: "subscriber",
                value: 0,
            }
          });

        console.log('form data: ',event.target)

        const data = new FormData(event.target)

        fetch(`https://${props.acDomain}.activehosted.com/proc.php`, {
        method: "POST",
        body: data,
        mode: "no-cors",
        })
        .then(res => {
            console.log(res)
            setFormSubmitted(true)
            setTimeout(() => {
            setFormSubmitted(false)
            }, 5000)
        })
        .catch(error => console.log("Request failed", error))

        setSuccess(true);
        console.log(`/${props.slug}/success/`)
        navigate(`/${props.slug}/success/`);
        // console.log(window.dataLayer)
    }

  return (

    <>

        <div className="h-full bg-gradient-to-r from-[#662e91] to-[#e34a63] mx-auto px-4 sm:px-6 md:px-8 py-8">

            <div className="bg-white mx-auto max-w-lg px-4 sm:px-6 md:px-8 rounded-lg border-gray-300 shadow-lg py-8">

                <div className="sm:flex-auto mx-auto text-center">

                    <img className="mx-auto" src={props.image}/>

                </div>

                
                <div className="sm:flex-auto text-center mt-2">

                    <h3 className="text-2xl font-medium leading-6 text-gray-900">{props.artist}</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Sign up for first access to the Australian Regional Tour tickets.</p>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Wondering where your Boy & Bear email is? Please check your spam folder in case it went walkabout :-)</p>

                </div>

                {/* clean up! */}
                <form method="POST" onSubmit={e => handleSubmit(e)}>

                    <input type="hidden" name="u" value="1" />
                    <input type="hidden" name="f" value="1" />
                    <input type="hidden" name="s" />
                    <input type="hidden" name="c" value="0" />
                    <input type="hidden" name="m" value="0" />
                    <input type="hidden" name="act" value="sub" />
                    <input type="hidden" name="v" value="2" />
                    <input type="hidden" name="or" value="5a1e2bb7beaa08717a7614f5a4ca3c1d" />

                    <div className="pt-8">
                        <div className="sm:col-span-4">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email*</label>
                            <div className="mt-1">
                                <input name="email" type="email" value={email || ""} onChange={(event) => {
                                    setEmail(event.target.value);
                                }} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" required />
                            </div>
                        </div>
                    </div>

                    <div className="pt-8">
                        <div className="sm:col-span-4">
                            <label htmlFor="firstname" className="block text-sm font-medium text-gray-700">First Name*</label>
                            <div className="mt-1">
                                <input name="firstname" type="text" value={firstName || ""} onChange={(event) => {
                                    setFirstName(event.target.value);
                                }} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                            </div>
                        </div>
                    </div>

                    {/* Country select */}
                    <div className="pt-8">
                        <div className="sm:col-span-4">
                        <Listbox name="field[7]" value={country.name} onChange={(event) => {
                                    setCountry(event);
                                }}>
                            {({ open }) => (
                                <>

                                <Listbox.Label className="block text-sm font-medium text-gray-700">Country*</Listbox.Label>
                                
                                <div className="relative mt-1">

                                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                    <span className="block truncate">{country.name || '---'}</span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                    </Listbox.Button>

                                    <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">

                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">

                                            {Country.getAllCountries().map((country) => (
                                                
                                                <Listbox.Option
                                                    key={country.isoCode}
                                                    className={({ active }) =>
                                                    classNames(
                                                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                        'relative cursor-default select-none py-2 pl-8 pr-4'
                                                    )
                                                    }
                                                    value={country}
                                                >

                                                    {({ selected, active }) => (
                                                    <>
                                                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                        {country.name}
                                                        </span>

                                                        {selected ? (
                                                        <span
                                                            className={classNames(
                                                            active ? 'text-white' : 'text-indigo-600',
                                                            'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                                            )}
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                        ) : null}
                                                    </>
                                                    )}

                                                </Listbox.Option>

                                            ))}
                                        </Listbox.Options>

                                    </Transition>

                                </div>
                                </>
                            )}
                            </Listbox>
                    </div>
                    </div>

                    {/* Region select */}
                    <div className="pt-8">
                        <div className="sm:col-span-4">
                        <Listbox name="field[4]" value={stateRegion} onChange={(event) => {
                                    console.log('changed value: ')
                                    console.log(event)
                                    setStateRegion(event);
                                }}>
                            {({ open }) => (
                                <>

                                <Listbox.Label className="block text-sm font-medium text-gray-700">State/Region*</Listbox.Label>
                                
                                <div className="relative mt-1">

                                    <Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
                                    <span className="block truncate">{stateRegion || '---'}</span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                    </Listbox.Button>

                                    <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">

                                        <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                            
                                            {states.map((state) => (
                                                
                                                <Listbox.Option
                                                    key={state}
                                                    className={({ active }) =>
                                                    classNames(
                                                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                        'relative cursor-default select-none py-2 pl-8 pr-4'
                                                    )
                                                    }
                                                    value={state}
                                                >

                                                    {({ selected, active }) => (
                                                    <>
                                                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                        {state}
                                                        </span>

                                                        {selected ? (
                                                        <span
                                                            className={classNames(
                                                            active ? 'text-white' : 'text-indigo-600',
                                                            'absolute inset-y-0 left-0 flex items-center pl-1.5'
                                                            )}
                                                        >
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                        ) : null}
                                                    </>
                                                    )}

                                                </Listbox.Option>

                                            ))}
                                        </Listbox.Options>

                                    </Transition>

                                </div>
                                </>
                            )}
                            </Listbox>
                        </div>
                    </div>

                    <div className="pt-8">
                        <div className="sm:col-span-4">
                            <label htmlFor="field[3]" className="block text-sm font-medium text-gray-700">City/Town*</label>
                            <div className="mt-1">
                                <input name="field[3]" type="text" value={city || ""} onChange={(event) => {
                                    setCity(event.target.value);
                                }} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                            </div>
                        </div>
                    </div>

                    <div className="pt-8">
                        <div className="sm:col-span-4">
                            <label htmlFor="field[5]" className="block text-sm font-medium text-gray-700">Post Code / ZIP*</label>
                            <div className="mt-1">
                                <input name="field[5]" type="text" value={postCode || ""} onChange={(event) => {
                                    setPostCode(event.target.value);
                                }} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                            </div>
                        </div>
                    </div>

                    <div className="pt-8">
                        <div className="sm:col-span-4">
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Phone</label>
                            <div className="mt-1">
                                <input name="phone" type="text" value={mobileNumber || ""} onChange={(event) => {
                                    setMobileNumber(event.target.value);
                                }} className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md" />
                            </div>
                        </div>
                    </div>

                    {/* SUBMIT BUTTON */}
                    <button type="submit" className="w-full py-2 my-8 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        Sign me up!
                    </button>

                    {
                        success && 
                    <div className="rounded-md bg-green-50 p-4">
                        <div className="flex">
                            <div className="flex-shrink-0">
                            <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                            </div>
                            <div className="ml-3">
                            <p className="text-sm font-medium text-green-800">Successfully uploaded</p>
                            </div>
                            <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                type="button"
                                className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                >
                                <span className="sr-only">Dismiss</span>
                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>
                    }

                    {
                        error && 
                        
                        <div className="rounded-md bg-red-50 p-4">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                                </div>
                                <div className="ml-3">
                                <h3 className="text-sm font-medium text-red-800">{error}</h3>
                                <div className="mt-2 text-sm text-red-700">
                                    <ul role="list" className="list-disc space-y-1 pl-5">
                                    <li>Check the email you gave is correct</li>
                                    <li>Check you've inputted all the required fields</li>
                                    </ul>
                                </div>
                                </div>
                            </div>
                        </div>
                    }
                    {/* {
                        loading && <div>Loading!</div>
                    } */}

                </form>

            </div>

            <div className="flex items-center justify-center pt-4">

                <img className="object-contain h-20 w-30 w-auto" src="https://drive.google.com/uc?export=view&id=1vlhQemRa3BVZlT6Q7po46ArjhfzqaWnR"/>

            </div>

        </div>


        

        </>
  )
}